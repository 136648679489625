
import { defineComponent, onMounted, ref, computed, reactive, Ref, watch } from "vue";
import TitleCard from "@/components/cards/TitledCard.vue"
import NetworkService from "@/core/services/network-service";
import { useNotification } from "@kyvg/vue3-notification";
import { useAuth } from "@/core/composables/useAuth";
import { useLocation } from '@/core/composables/useLocation';
import { Town, State, Area   } from '@/models'; //Area, State,

// 

export default defineComponent({
    name: "orders",
    components: {
        "titled-card": TitleCard,
    },
    setup() {
        const loading = ref(false);
        const notification = useNotification();
        const { getUser, user } = useAuth();
        const { locations, getLocations, isLoading } = useLocation();

        
        const myRoutes = reactive({
            state: null as unknown as State,
            town: [] as unknown as Town[],
            areas: [] as Area[]
        })

        const states: Ref<State[]> = ref([]);
       

        const towns = computed(() => states.value?.find((e: State) => e.name == myRoutes.state?.name)?.towns ?? [])
        const areas = computed(() => towns.value?.filter((e: Town) => myRoutes.town.map((x)=> x.name).includes(e.name))
            .map((y)=> y.areas).flat());

        const onChangeRoute = () => {
            
            
        }

        const onChangeState = () => {
            myRoutes.town = towns.value.filter((e) => myRoutes.town.find((x)=> e.name == x.name));
            if (myRoutes.town.length < 1) {
                myRoutes.areas = [];
            }
        }

        const onChangeTown = () => {  
            myRoutes.areas = areas.value.filter((e)=> myRoutes.areas.find((x)=> e.name == x.name));
        }
 

        const submit = async () => {
            if (!myRoutes.areas || !myRoutes.state || !myRoutes.town) return;
            loading.value = true;
            
            const response = await NetworkService.post("/rider/update-routes", {
                state: myRoutes.state.name,
                town: myRoutes.town.map((e)=> e.name),
                areas: myRoutes.areas.map((e) => e.name),
            });
            loading.value = false;
            if (response.status == 200) {

                getUser();

                notification.notify({
                    type: 'success',
                    title: "Routes",
                    text: response.data.message
                });
                loading.value = false;
            }else{
                notification.notify({
                    type: 'error',
                    title: "Routes",
                    text:  "unable to update Route. Try again"
                });
            }
        }

        const getUserRoutes = () => {
            
            const userState = states.value.find((e: State) => e.name == user.value.state);
            const userAreas = user.value.area as unknown as string;
            const userTowns = user.value.town as unknown as string



            if (userState) {
                myRoutes.state = userState,
                myRoutes.town = myRoutes.state.towns.filter((e: Town) => userTowns.includes(e.name)) as Town[]
                myRoutes.areas = myRoutes.town.map((e)=> e.areas).flat().filter((e) => userAreas.includes(e.name))

            }
        }

        const fetchLocations = async (page?: number) => {
            await getLocations();

            states.value = locations.value;
        };

        onMounted( async() => {
            await fetchLocations()
            getUserRoutes();

        })


        return {
            loading,
            states,
            towns,
            areas,
            myRoutes,
            submit,
            onChangeRoute,
            onChangeState,
            onChangeTown
        }
    },
});
