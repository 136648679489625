<template>
    <div class="w-full h-[100vh] fixed top-0 bottom-0 inset-0 z-50  bg-[#00010178]" id="layer" @click="closeSidebar"></div>
   
   <div class="  mr-4 w-[280px] fixed lg:left-0 left-[-280px] top-0 h-[100vh] bg-white z-50 ease-in-out duration-200 border-[#e5e7eb] border-r" id="sidebar">
       <div class="navbar-logo flex lg:justify-center items-start pt-12 pr-4 lg:ml-0 ml-[17px]">
          <!-- <img src="../assets/images/rideeatLogo.svg" alt="Rideeat-logo"/> -->
          <img src="../assets/images/logo/riderLogo.svg" alt="Rideeat-logo " class="w-[95px]"/>
        </div>          
       <div class=" mt-16" id="sidebar">
           <ul class="lg:pl-14 pl-2 pr-7 sidebar_ul"> 
                   <li class=" pl-3">
                       <router-link :to="{name: 'dashboard'}" class="nav-link" :key="$route.fullPath">
                           <span class="flex justify-start items-center rounded-2xl pl-3 py-3.5 mb-4 cursor-pointer ease-in-out duration-150">
                               <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="dash-icon">
                                 <path class="side-icon" d="M9.13478 20.7733V17.7156C9.13478 16.9351 9.77217 16.3023 10.5584 16.3023H13.4326C13.8102 16.3023 14.1723 16.4512 14.4393 16.7163C14.7063 16.9813 14.8563 17.3408 14.8563 17.7156V20.7733C14.8539 21.0978 14.9821 21.4099 15.2124 21.6402C15.4427 21.8705 15.7561 22 16.0829 22H18.0438C18.9596 22.0023 19.8388 21.6428 20.4872 21.0008C21.1356 20.3588 21.5 19.487 21.5 18.5778V9.86686C21.5 9.13246 21.1721 8.43584 20.6046 7.96467L13.934 2.67587C12.7737 1.74856 11.1111 1.7785 9.98539 2.74698L3.46701 7.96467C2.87274 8.42195 2.51755 9.12064 2.5 9.86686V18.5689C2.5 20.4639 4.04738 22 5.95617 22H7.87229C8.55123 22 9.103 21.4562 9.10792 20.7822L9.13478 20.7733Z" 
                                 fill="#2C2E2F"/>
                               </svg>
                               <p class="grayText ml-3 text-base font-[500]">Dashboard</p>
                           </span>
                       </router-link>
                   </li>
                   <li class="pl-3">
                       <router-link :to="{name: 'orders'}" class="nav-link" :key="$route.fullPath">
                           <span class="flex justify-start items-center nav-link py-3.5 btn rounded-2xl pl-3 mb-4 cursor-pointer ease-in-out duration-150">
                               <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                   <g id="Iconly/Bold/Category">
                                       <g id="Category">
                                           <path class="side-icon" id="Category_2" fill-rule="evenodd" clip-rule="evenodd" d="M4.53988 2H7.91988C9.32988 2 10.4599 3.15 10.4599 4.561V7.97C10.4599 9.39 9.32988 10.53 7.91988 10.53H4.53988C3.13988 10.53 1.99988 9.39 1.99988 7.97V4.561C1.99988 3.15 3.13988 2 4.53988 2ZM4.53988 13.4697H7.91988C9.32988 13.4697 10.4599 14.6107 10.4599 16.0307V19.4397C10.4599 20.8497 9.32988 21.9997 7.91988 21.9997H4.53988C3.13988 21.9997 1.99988 20.8497 1.99988 19.4397V16.0307C1.99988 14.6107 3.13988 13.4697 4.53988 13.4697ZM19.46 2H16.08C14.67 2 13.54 3.15 13.54 4.561V7.97C13.54 9.39 14.67 10.53 16.08 10.53H19.46C20.86 10.53 22 9.39 22 7.97V4.561C22 3.15 20.86 2 19.46 2ZM16.08 13.4697H19.46C20.86 13.4697 22 14.6107 22 16.0307V19.4397C22 20.8497 20.86 21.9997 19.46 21.9997H16.08C14.67 21.9997 13.54 20.8497 13.54 19.4397V16.0307C13.54 14.6107 14.67 13.4697 16.08 13.4697Z" fill="#2C2E2F"/>
                                       </g>
                                   </g>
                              </svg>      
                               <p class="grayText ml-3 text-base font-[500]">Orders</p>
                           </span>
                      </router-link>
                   </li> 
                   <li class="pl-3">
                    <router-link :to="{ name: 'withdraw' }" class="nav-link">
                        <span
                            class="flex justify-start items-center py-3.5 btn rounded-2xl pl-3 mb-4 cursor-pointer ease-in-out duration-150 withdraw_wrap">
                            <div class=" bg-no-repeat bg-[length:27px_24px] w-[25px] h-[24px] withdraw_btn">
                              <img src='../assets/images/withdrawblack.png' alt="">
                            </div>
                            <p class="grayText ml-3 text-base font-[500]">Withdraw</p>
                        </span>
                    </router-link>
                </li>
               <li class="pl-3">
                   <router-link :to="{name: 'routes'}" class="nav-link">
                       <span class="flex justify-start items-center py-3.5 btn rounded-2xl pl-3 mb-4 cursor-pointer ease-in-out duration-150">
                           <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path class="side-icon" fill-rule="evenodd" clip-rule="evenodd" d="M20.4022 13.5801C20.7599 13.7701 21.0358 14.0701 21.23 14.3701C21.6081 14.9901 21.5775 15.7501 21.2096 16.4201L20.4942 17.6201C20.1161 18.2601 19.4109 18.6601 18.6853 18.6601C18.3277 18.6601 17.9291 18.5601 17.6021 18.3601C17.3364 18.1901 17.0298 18.1301 16.7027 18.1301C15.691 18.1301 14.8428 18.9601 14.8121 19.9501C14.8121 21.1001 13.8719 22.0001 12.6967 22.0001H11.3068C10.1213 22.0001 9.18113 21.1001 9.18113 19.9501C9.16069 18.9601 8.31247 18.1301 7.30073 18.1301C6.96348 18.1301 6.6569 18.1901 6.40141 18.3601C6.07438 18.5601 5.6656 18.6601 5.31813 18.6601C4.58232 18.6601 3.87717 18.2601 3.49905 17.6201L2.7939 16.4201C2.41577 15.7701 2.39533 14.9901 2.77346 14.3701C2.93697 14.0701 3.24356 13.7701 3.59102 13.5801C3.87717 13.4401 4.06112 13.2101 4.23486 12.9401C4.74584 12.0801 4.43925 10.9501 3.57059 10.4401C2.55885 9.87012 2.23182 8.60012 2.81434 7.61012L3.49905 6.43012C4.09178 5.44012 5.35901 5.09012 6.38097 5.67012C7.27007 6.15012 8.42488 5.83012 8.94608 4.98012C9.10959 4.70012 9.20157 4.40012 9.18113 4.10012C9.16069 3.71012 9.27311 3.34012 9.46728 3.04012C9.8454 2.42012 10.5301 2.02012 11.2761 2.00012H12.7171C13.4734 2.00012 14.1581 2.42012 14.5362 3.04012C14.7201 3.34012 14.8428 3.71012 14.8121 4.10012C14.7917 4.40012 14.8837 4.70012 15.0472 4.98012C15.5684 5.83012 16.7232 6.15012 17.6225 5.67012C18.6342 5.09012 19.9117 5.44012 20.4942 6.43012L21.1789 7.61012C21.7716 8.60012 21.4446 9.87012 20.4227 10.4401C19.554 10.9501 19.2474 12.0801 19.7686 12.9401C19.9321 13.2101 20.1161 13.4401 20.4022 13.5801ZM9.10959 12.0101C9.10959 13.5801 10.4075 14.8301 12.012 14.8301C13.6164 14.8301 14.8837 13.5801 14.8837 12.0101C14.8837 10.4401 13.6164 9.18012 12.012 9.18012C10.4075 9.18012 9.10959 10.4401 9.10959 12.0101Z" fill="#2C2E2F"/>
                           </svg>
                           <p class="grayText ml-3 text-base font-[500]">My Routes</p>
                       </span>
                   </router-link>
               </li>
           </ul>
           <div class="lg:pl-16 pl-2 pr-7 mt-4  cursor-pointer">
               <span class="flex justify-start items-center py-3.5 btn rounded-2xl pl-3 mb-4 cursor-pointer ease-in-out duration-150" @click="signOut">
                   <img src="../assets/images/Logout.svg" alt="Dashboard"/>
                   <p class="ml-3 text-base font-[500] text-[#FF1E54]">Log out</p>
               </span>
           </div>
       </div>
   </div>
</template>

<script >
import { Actions } from "@/store/enums/StoreEnums";
import { computed, defineComponent, watch } from "@vue/runtime-core"
import { useStore } from "vuex";
import { useRouter, useRoute, Router } from "vue-router";
import { useToggle } from "@/core/composables/useToggle";
export default defineComponent({
    name: 'sidebar',

    setup() {

        const { open: openSideBar, close: closeSidebar, } = useToggle('sidebar', 'layer');
        const store = useStore();
        const router = useRouter();
        const route = useRoute();

        const signOut = () => {
            store.dispatch(Actions.LOGOUT).then(() => {
                router.push({ name: "login" });
            });
        };

        watch(
            () => route.path,
            (to, from) => {
                closeSidebar()
            }
        );
        return { signOut, closeSidebar }

    }
});
</script>

<style scoped></style>