
import { defineComponent, onMounted, ref, computed, PropType } from "vue";
import type { Header, Item, ClickRowArgument } from "vue3-easy-data-table";
import { Order } from '@/models';
import { useOrder } from '@/core/composables/useOrder';
import { useRouter } from "vue-router";
import NumberFormatter from "@/components/NumberFormatter.vue";
import OrderItem from "./order-item.vue";
import Spinner from '@/components/Spinner.vue';



export default defineComponent({
    name: "orders",
    setup() {
        const router = useRouter();
        const loading = ref(false)
        const { orders, getOrders, paginationData } = useOrder();
        const headers = [
            { text: "Order", value: "vendor" },
            { text: "Processing time", value: "time" },
            { text: "Delivery Method", value: "delivery_method" },
            { text: "Delivery Fee", value: "delivery_fee" },
            { text: "Status", value: "status_text", sortable: true },
            { text: "Date", value: "created_at" }
        ];


        const isFirstPage = ref();
        const isLastPage = ref();
        const next = ref();
        const prev = ref();
        const items = ref([] as unknown as Order[]);

        const fetchOrders = async (page?: number) => {
            loading.value = true;
            await getOrders(page);
            loading.value = false
            items.value = orders.value;
            isFirstPage.value = 1;
            isLastPage.value = paginationData.value.last_page;
            next.value = paginationData.value.next;
            prev.value = paginationData.value.prev;
            loading.value = false
        };

        onMounted(async () => {
            fetchOrders();
        });



        const onClickRow = (row: ClickRowArgument) => {
            router.push({ name: "viewOrder", params: { uuid: row.uuid } });
        };

        const nextPage = (page: number) => {
            fetchOrders(page);
        };

        const prevPage = (page: number) => {
            fetchOrders(page);
        };

        const pendingOrders = computed(() => orders.value.filter((e: any) => e.status < 50))
        const OtherOrders = computed(() => orders.value.filter((e: any) => e.status >= 15))

        return {
            loading,
            headers,
            items,
            paginationData,
            isFirstPage,
            isLastPage,
            nextPage,
            prevPage,
            next,
            prev,
            pendingOrders,
            OtherOrders,
            onClickRow
        };
    },
    components: {Spinner, NumberFormatter, OrderItem }
});
