<template>
    <div class="min-h-[100vh]">
      <Navbar />
        <div class="sm:px-5 "> 
          <router-view />
        </div>
        <div>
          <sidebar />
          <BottomNavigations/>
        </div>
    </div>
  </template>
  <script>
  import { computed, defineComponent, nextTick, onMounted } from "vue";
  import Navbar from '../components/Navbar.vue';
  import Sidebar from '../components/Sidebar.vue';
  import BottomNavigations from "@/components/BottomNavigations.vue";
  export default defineComponent({
    name: 'Index',
    components: {
      Navbar,
      Sidebar,
      BottomNavigations
    },
    setup() {
     
    }
  })
  
  </script>
  
  
  <style>
  
  </style>
  