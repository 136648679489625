
import { defineComponent } from 'vue'

export default defineComponent({
    props:{
        title:{
            type: String,
            required: true
        },
        optional: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    setup() {


        return { }
    },
})
