
import { PropType, defineComponent, ref } from 'vue';

export default defineComponent({
    props: {
        openModal:{
            type: Boolean as PropType<Boolean>,
            required: true
        },
    },
    emits: ['toggleModal'],
    setup(props, { emit }) {
        const state = ref(props.openModal);
        const toggleModal = ()=>{
            emit('toggleModal')
        }
        return {
            state,
            toggleModal
        };
    },
    components: {  }
})
