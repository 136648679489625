import axios, { AxiosRequestConfig, AxiosResponse, AxiosInstance } from 'axios';
import { RequestErrors } from '@/store/errors/RequestErrors';
import { handleError } from 'vue';
const defaultConfig = {
    baseURL: process.env.VUE_APP_BASE_URL as string,
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem("auth_token")}`,
    },
  };

const defaultClient: AxiosInstance = axios.create(defaultConfig);

class NetworkService {
    /**
     * 
     * @param url 
     * @param config 
     * @returns config?:AxiosRequestConfig<any>
     */
    public static async get(
        url:string,
        config?:AxiosRequestConfig<any>
    ):Promise<AxiosResponse>{
        try {
              const response =  defaultClient.get(url, config)
              return await response
            
        } catch (error:any) {
            this.handleError(error.response);
            return error.response as AxiosResponse
        }
    }

    /**
     * 
     * @param url 
     * @param payload 
     * @param config 
     * @returns config?:AxiosRequestConfig<any>
     */
    public static async post(
        url:string,
        payload: Object,
        config?:AxiosRequestConfig<any>
    ):Promise<AxiosResponse> { 
        try {
            const response = await defaultClient.post(url, payload, config);
            return response;
        } catch (error:any) {
            this.handleError(error.response);
            
            return error.response as AxiosResponse
        }
         
    }

    public static async update(
        url:string,
        payload: Object,
        config?:AxiosRequestConfig<any>
    ):Promise<AxiosResponse>{
        try {
            const response = await defaultClient.put(url, payload, config);
            return response;
        } catch (error:any) {
            this.handleError(error.response);
            
            return error.response as AxiosResponse
        }
    }

    public static handleError(response: AxiosResponse){
        //Tope implement handle error functionality
       let error =  RequestErrors(response);

        if (response.status == 401 && window.location.pathname != "/login") {
            localStorage.removeItem('auth_token')
            localStorage.removeItem('rideeat')
            window.location.href = "/login"
        }

       if (response.status == 401) {
            return response;
       }
       return error;
       
    }
    
    get error (){
        
        return handleError;
    }
}

export default NetworkService;