
import { useResetPassword } from '@/core/composables/useResetPassword';
import TextInput from '@/components/forms/TextInput.vue';
import { defineComponent, onMounted, ref } from '@vue/runtime-core';
import { useRoute, useRouter } from "vue-router";
// import { useResetPassword } from "../../ core/composables/useResetPassword";
import { useNotification } from "@kyvg/vue3-notification";
import Spinner from '@/components/Spinner.vue';

interface Payload {
    password: String,
    token: string
}
export default defineComponent({
    components: {
        TextInput,
        Spinner,
    },
    setup() {
        const notification = useNotification()
        const route = useRoute();
        const router = useRouter();
        const id = route.params.id;
        const details = ref({
            password: '',
            confirmPassword: ''
        });

        const { verified, loading, verifyToken, resetPassword } = useResetPassword();
        
        const send = async () => {
            let payload = { password: details.value.password, token: id }

            // check if the confirmPassword is = Password
            if (details.value.confirmPassword != details.value.password) {
                notification.notify({ type: 'warn', title: "Authorization", text: "Opps, Passwords does not match" });
                return;
            }
            let response = await resetPassword(payload as Payload);
            if (response) {
                notification.notify({
                    type: 'success',
                    title: "Authorization",
                    text: response.message,
                })
                router.push({ name: "login" })
            }

        }

        onMounted(() => {
            verifyToken(id as String);


        })


        return {
            verified,
            details,
            loading,
            send,
        }
    }
});


