
import { computed, defineComponent, ref, watch } from "@vue/runtime-core"
import { useStore } from "vuex";
import NetworkService from "@/core/services/network-service";
import { useNotification } from "@kyvg/vue3-notification";
import { Actions } from "@/store/enums/StoreEnums";
import { useRouter, useRoute, Router } from "vue-router";

export default defineComponent({
    name: 'navbar',

    setup() {
        const notification = useNotification();
        const store = useStore();
        const user = computed(() => store.getters.currentUser);
        const router = useRouter();
        const openModal = ref(false)
        const loading = ref(false)
        console.log(user.value);
        const message = ref({
            reason: ""
        })

        const deleteAccount = async () => {
            const res = await NetworkService.post('rider/delete-account', message.value)
            console.log(res.data);

            if (res.status == 200) {
                loading.value = false;
                notification.notify({
                    type: 'success',
                    title: "OTP",
                    text: "Account deleted successfully",
                })
                openModal.value = true;
                signOut()
                return;
            }
            notification.notify({
                type: 'error',
                title: "OTP",
                text: res.data.message,
            })
            loading.value = false;
        }

        const signOut = () => {
            store.dispatch(Actions.LOGOUT).then(() => {
                router.push({ name: "login" });
            });
        };


        return { user, openModal, message, deleteAccount }


    }
});
