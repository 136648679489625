import { ref, Ref } from 'vue'
import NetworkService from '../services/network-service';
import { useNotification } from "@kyvg/vue3-notification";
import type { User } from "@/models";

interface ResetPasswordComposable {
    loading:Ref<Boolean>
    resetPassword:(payload:Payload)=> Promise<Response>,
    verifyToken:(payload:String)=> Promise<void>,
    verified:Ref<Boolean>
}
interface Payload { 
    password: String,
    token: string
}
interface Response {
    message:string
    status:string

}


 export const useResetPassword = ():ResetPasswordComposable =>{
    const loading = ref(false);
    const verified = ref(true);
    const notification = useNotification()
    const resetPassword =async (payload: Payload): Promise<Response>=>{
        const response =ref({message:'', status:'error'});
        loading.value=true;
         await  NetworkService.post("rider/auth/reset-password", payload).then((resp)=>{
            if (resp.status == 200){
                response.value.message = resp.data.message; 
                response.value.status = 'success'; 
            }
        }).catch((error)=>response.value.message = error.response.data.message);

        loading.value=false;
        
        return response.value;
    }



    const verifyToken = async (token: any):Promise<void>=>{
        loading.value=true;
        try {
            const response = await NetworkService.get("rider/auth/verify-token/?code="+ token);
            console.log(response);
            if(response.status != 200){
                verified.value =  false;
            }
            
            loading.value=false;
        } catch (error) {
            loading.value=false;
            verified.value =  false;
            
        }
        
    }

    return {
        verifyToken,
        verified,
        loading,
        resetPassword,
    }
}