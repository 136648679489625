
import TitledCard from '@/components/cards/TitledCard.vue'
import { useRoute } from 'vue-router';
import { defineComponent, onMounted, ref } from 'vue';
import { useOrder } from '@/core/composables/useOrder'; 
import NumberFormatter from '@/components/NumberFormatter.vue';  
import CenteredModal from '@/components/CenteredModal.vue';  
import Spinner from '@/components/Spinner.vue';
import { useNotification } from '@kyvg/vue3-notification';

export default defineComponent({
    components: { TitledCard, NumberFormatter, Spinner, CenteredModal },
    setup() {
        const notification = useNotification();
        const { isLoading, order,
             getOrder, acceptOrder, rejectOrder, startDelivery, deliver } = useOrder();

        const uuId = useRoute().params.uuid;
        const rejectionNote = ref('out of stock');
        const openModal = ref(false)
        const code = ref('');

        onMounted(async () => {
            await getOrder(uuId); 
        });

        const acceptOrderHandler = async ()=>{
            await acceptOrder(uuId);
        }

        const rejectOrderHandler = async ()=>{
            await rejectOrder(uuId, rejectionNote.value);
        }

        const startDeliveryHandler = async ()=>{
            await startDelivery(uuId);
        }

        const deliverHandler = async ()=>{
            let response = await deliver({uuid:uuId.toString(), code: code.value});
            if (response) {
                openModal.value = false;
                code.value= ''
                return;
            }
            notification.notify({
                type: 'error',
                title: "Authorization",
                text: "Incorrect Package Security Code"
            })
        }

        return {
            code,
            order,
            isLoading,
            openModal,
            startDeliveryHandler,
            acceptOrderHandler,
            deliverHandler,
            rejectOrderHandler
        }
    }
})
