import { ref, Ref } from 'vue'
import NetworkService from '@/core/services/network-service';
import type { Order, ResponseMeta } from '@/models';
import { cloneDeep } from "lodash";
import { useNotification } from "@kyvg/vue3-notification";
import { defaultOrder, defaultResponseMeta } from '@/core/data/orderData';
import { useRouter } from 'vue-router';

interface OrderComposable {
    isLoading: Ref<boolean>;
    orders: Ref<Order[]>;
    order: Ref<Order>;
    getOrder: (uuid: any) => void;
    getOrders: (page?: number) => void;
    acceptOrder: (uuid: any) => void;
    rejectOrder: (uuid: any, note: string) => void;
    startDelivery: (uuid: any) => void;
    deliver: ({ uuid, code }: { uuid: string, code: string }) => Promise<boolean>;
    paginationData: Ref<ResponseMeta>;
}

export const useOrder = (): OrderComposable => {
    const isLoading = ref(false);
    const order: Ref<Order> = ref(cloneDeep(defaultOrder) as unknown as Order);
    const orders: Ref<Order[]> = ref([]);
    const paginationData: Ref<ResponseMeta> = ref(cloneDeep(defaultResponseMeta) as unknown as ResponseMeta);

    const getOrders = async (page?: number) => {
        //  console.log(page);
        isLoading.value = true;
        let response = await NetworkService.get('rider/orders?page=' + page);
        isLoading.value = false;
        orders.value = response.data.data.map((e: any) => {
            e.foods = JSON.parse(e.foods)
            return e;
        })


        let meta = { ...response.data.links, ...response.data.meta }
        paginationData.value = meta

    }
    const getOrder = async (uuid: any) => {
        isLoading.value = true;
        let response = await NetworkService.get('rider/order/' + uuid);
        let data = response.data.data;

        data.foods = JSON.parse(data.foods)
        data.flow = JSON.parse(data.flow)

        order.value = data;
        isLoading.value = false;
    }

    const acceptOrder = async (uuid: any) => {
        isLoading.value = true;
        let response = await NetworkService.post('rider/order/' + uuid + '/pickup', {});
        if (response.status == 200) { await getOrder(uuid); }
        isLoading.value = false;
    }

    const rejectOrder = async (uuid: any, note: string) => {
        isLoading.value = true;
        let response = await NetworkService.post('rider/order/' + uuid + '/reject', { note });
        if (response.status == 200) {
            window.history.back()
        }
        isLoading.value = false;
    }

    const startDelivery = async (uuid: any) => {
        isLoading.value = true;
        let response = await NetworkService.post('rider/order/' + uuid + '/processing', {});
        if (response.status == 200) { await getOrder(uuid); }
        isLoading.value = false;
    }


    const deliver = async ({ uuid, code }: { uuid: string, code: string }) => {
        isLoading.value = true;
        let response = await NetworkService.post('rider/order/' + uuid + '/completed', { code: code });
        if (response.status == 200) {
            await getOrder(uuid);
            isLoading.value = false;
            return true
        }
        isLoading.value = false;
        return false;
    }


    return {
        isLoading,
        order,
        orders,
        paginationData,
        getOrders,
        acceptOrder,
        rejectOrder,
        deliver,
        startDelivery,
        getOrder,
    }
}