

import { useAuth } from '@/core/composables/useAuth';
import TextInput from '@/components/forms/TextInput.vue';
import { defineComponent, ref } from '@vue/runtime-core';
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useNotification } from "@kyvg/vue3-notification";

export default defineComponent({
    components:{TextInput, },

    setup() {
        
        const notification = useNotification();
        const store = useStore();
        const loading = ref(false);
        const router = useRouter();
        const {  user } = useAuth()

        const details = ref({
            email: '',
            password: '',
            device: ''
        });

       const submit = async () => {
            loading.value = true;
            details.value.device = JSON.parse(localStorage.getItem('device') || 'null');
            let {status, data} = await store.dispatch(Actions.LOGIN, details.value);
            
            if (status == 200){
                loading.value = false;
                window.location.href= '/orders'
                return;
            }
            notification.notify({
                type: 'error',
                title: "Authorization",
                text: data.message
            })
            loading.value = false;
            return;
        }

        return {
         user, details, submit, loading
        }
    }
});


