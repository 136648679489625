$(document).ready(() => {
    $(".search_close").click(()=> {
        $(".search_box").removeClass("toggleSearch"); 
    }); 
    $(".sidebar-btn").click(()=> {
        $("#sidebar").addClass("toggleSidebar"); 
    })
    $(".closeSidebar").click(() => {
        $("#sidebar").removeClass("toggleSidebar"); 
    });

    $('#rider-dropdown').click(() => {
        $('.drop_ul').toggleClass('toggleDropdown');
     });
})