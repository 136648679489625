import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import './assets/tailwind.css';
import './assets/css/dashboard.css';
import './assets/js/dashboard.js';
import 'vue3-easy-data-table/dist/style.css';
import store from "./store";
import 'tw-elements';
import Notifications from '@kyvg/vue3-notification'
import Vue3EasyDataTable from 'vue3-easy-data-table';
import VueMultiselect from 'vue-multiselect'
import "vue-multiselect/dist/vue-multiselect.css"

import moment from 'moment'

const app = createApp(App)

app.config.globalProperties.$filters = {
    formatDate(date:any) {
      return moment(date).format('MMMM Do YYYY, h:mm:ss a')
    },
  }
  
app.use(Notifications)
.use(router)
.use(store)
.component("Multiselect", VueMultiselect)
.component('EasyDataTable', Vue3EasyDataTable)
.mount('#app');