
import BarChart from './BarChart.vue';
import type { DashboardDetails } from '@/models';
import NumberFormatter from "@/components/NumberFormatter.vue";
import { onMounted, ref } from 'vue';
import NetworkService from '@/core/services/network-service';
import OrdersTable from './orders/orders-table.vue';
import Spinner from '@/components/Spinner.vue';

export default {
  name: "dashboard",
  components: {
    BarChart,
    OrdersTable,
    NumberFormatter,
    Spinner,
  },
  setup() {
    const dashboardDetails = ref({} as unknown as DashboardDetails);
    const loading = ref(false);

    const getDashboardDetails = async () => {
      loading.value = true;

      const response = await NetworkService.get('rider/dashboard/details')
      dashboardDetails.value = response.data
      loading.value = false;
      // console.log(dashboardDetails.value)
    }

    onMounted(() => {
      getDashboardDetails();
    })

    return {
      dashboardDetails,
      loading
    }
  }
};
