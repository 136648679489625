import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Dashboard from '@/views/Dashboard.vue'; 

import Orders from '@/views/orders/orders.vue';
import OrderItem from '@/views/orders/order.vue';

import MyRouteSettings from '@/views/settings/MyRoutes.vue'; 
import Login from '@/views/auth/login.vue';
import SignUp from '@/views/auth/SignUp.vue';
import ForgotPassword from '@/views/auth/forgotPassword.vue';
import ResetPassword from '@/views/auth/resetPassword.vue';
import IndexLayout from "@/views/index.vue";
import Home from "@/views/Home.vue";
import Withdraw from '@/views/withdrawal/Withdraw.vue';
import Profile from "@/views/Profile.vue"
import UploadImage from "@/views/settings/UploadImage.vue"

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login 
  }, 
  {
    path: '/forgot-password',
    name: 'forgotPassword',
    component: ForgotPassword 
  }, 
  {
    path: '/reset-password/:id',
    name: 'resetPassword',
    component: ResetPassword 
  }, 
  {
    path: '/sign-up',
    name: 'sign-up',
    component:SignUp
  },
  {
    path:'',
    component: IndexLayout,
    children: [
      {
        path: '',
        name: 'Home',
        component: Home
      },
      {
        path: "/dashboard",
        name: 'dashboard',
        component: Dashboard,
      },
      {
        path: 'orders',
        name: 'orders',
        component: Orders
      },
      {
        path: 'order/:uuid',
        name: 'viewOrder',
        component: OrderItem
      },
      {
        path: 'withdraw',
        name: 'withdraw',
        component: Withdraw
      },
      {
        path: 'my-routes',
        name: 'routes',
        component: MyRouteSettings
      }, 
      {
        path: '/profile',
        name: 'profile',
        component: Profile
      },
      {
        path: '/upload-image',
        name: 'upload-image',
        component: UploadImage
      },
    ],
    meta: {
        AuthRequired: true
    }
  }, 
] as RouteRecordRaw[]

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: 'active',
});

export default router
router.beforeEach((to,from,next)=>{
  if(to.matched.some(record => record.meta.AuthRequired)) {
      if (localStorage.getItem('rideeat') == null) {
          if(to.meta.AllowNoAuth){
             next();
             return false
          }
          next({
              path: '/login',
              name:'login'
          })
      } 

      //if mobile app then make the query consistent on all pages
      if(Object.keys(from.query).includes('mobileApp') && !Object.keys(to.query).includes('mobileApp')){
      next({path: `${to.fullPath}`, query:from.query})
      return;
      }
  }
  
  next();
});

router.beforeEach((to, from, next) => {
  if (to.path !== '/' && !to.matched.length) {
    next('/');
  } else {
    next();
  }
});