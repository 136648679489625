
import { ref, defineComponent, onMounted, computed } from "vue";
import NetworkService from "@/core/services/network-service";
import type { Header, Item } from "vue3-easy-data-table";
import { useNotification } from "@kyvg/vue3-notification";
import { useWithdrawal } from "@/core/composables/useWithdrawal";
import { Withdrawal } from '@/models';
import NumberFormatter from "@/components/NumberFormatter.vue";
import Spinner from '@/components/Spinner.vue';
export default defineComponent({
    name: 'withdrawal',
    components: { NumberFormatter, Spinner },
    setup() {
        const notification = useNotification();

        const otpDetails = ref({
            otp: '',
            amount: ''
        })
        const loading = ref(false)
        const openModal = ref(false)
        const { getWithdrawals, withdrawals, paginationData } = useWithdrawal();
        const headers: Header[] = [
            { text: "Status", value: "status" },
            { text: "Amount", value: "amount" },
            { text: "Date", value: "created_at" },
        ];

        const isFirstPage = ref();
        const isLastPage = ref();
        const next = ref();
        const prev = ref();
        const items = ref([] as unknown as Withdrawal[]);

        const fetchWithdraws = async (page?: number) => {
            await getWithdrawals(page);

            items.value = withdrawals.value;
            isFirstPage.value = 1;
            isLastPage.value = paginationData.value.last_page;
            next.value = paginationData.value.next;
            prev.value = paginationData.value.prev;
        };

        onMounted(async () => {
            await fetchWithdraws(1)

        });
        const nextPage = (page: number) => {
            fetchWithdraws(page);
        };

        const prevPage = (page: number) => {
            fetchWithdraws(page);
        };


        const submitWithdrawalDetails = async () => {
            loading.value = true;
            let response = await NetworkService.post('/vendor/initiate-otp', { amount: otpDetails.value.amount });
            if (response.status == 200) {
                loading.value = false;
                notification.notify({
                    type: 'success',
                    title: "OTP",
                    text: "A 4 digit pin has been sent to your phone number",
                })
                openModal.value = true;

                return;

            }
            notification.notify({
                type: 'error',
                title: "OTP",
                text: response.data.message,
            })
            loading.value = false;

        }
        const submitOtpAmount = async () => {
            let response = await NetworkService.post('/vendor/make-withdrawal', otpDetails.value);

            if (response.status == 200) {
                openModal.value = false;
                notification.notify({
                    type: 'success',
                    title: "OTP",
                    text: "Withdrawal successful",
                })
                otpDetails.value.amount = ""
                return;

            }
            notification.notify({
                type: 'error',
                title: "OTP",
                text: response.data.message,
            })
            loading.value = false;
            openModal.value = false;

        }
        return {
            headers,
            items,
            otpDetails,
            isFirstPage,
            isLastPage,
            nextPage,
            prevPage,
            next,
            prev,
            submitWithdrawalDetails,
            submitOtpAmount,
            openModal,
            loading,
            paginationData
        }
    }
})
