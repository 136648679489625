import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "border border-[#e0e0e0] bg-white pb-3 sm:px-6 px-3 rounded-lg mb-8" }
const _hoisted_2 = { class: "my-3 flex justify-between items-center border-b py-4 mb-6" }
const _hoisted_3 = { class: "flex items-center" }
const _hoisted_4 = { class: "whitespace-nowrap capitalize" }
const _hoisted_5 = {
  key: 0,
  class: "whitespace-nowrap capitaliz text-[12px] text-[#acabab] ml-2"
}
const _hoisted_6 = { class: "flex flex-wrap items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.title), 1),
        (_ctx.optional)
          ? (_openBlock(), _createElementBlock("p", _hoisted_5, "*Optional*"))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _renderSlot(_ctx.$slots, "actionButtonSlot")
      ])
    ]),
    _renderSlot(_ctx.$slots, "contentSlot")
  ]))
}