
import HandleDocument from "@/components/HandleDocument.vue";
import { onMounted, ref } from "vue";
import NetworkService from "@/core/services/network-service";
import { useNotification } from "@kyvg/vue3-notification";
import { useStore } from "vuex";
import { useUser } from '@/core/composables/useUser';
import FileUploader from "@/components/forms/FileUploader.vue";

export default {
  name: "uplaod-image",
  components: {
    HandleDocument,
    FileUploader
},
  setup() {
    const notification = useNotification();
    const store = useStore()
    const user = store.getters.currentUser;
    const { getUserDetails } = useUser();

    const logo = ref({
      photo: user.photo,
    });

    const myLogo = (url: any) => {
      logo.value.photo = url;
    };

    const uploadImage = async () => {

      if (logo.value.photo == '') {
        notification.notify({
          type: 'warn',
          title: "Update Profile",
          text: 'please select a valid file ',
        })
        return;
      }
      
      let response = await NetworkService.post("/rider/upload-photo", logo.value);

      if (response.status == 200) {
          getUserDetails();
        notification.notify({
          type: 'success',
          title: "Update Profile",
          text: response.data.message,
        })
        return;
      } 
      notification.notify({
          type: 'error',
          title: "Update Profile",
          text: response.data.message,
        })
    };

    onMounted(() => {
      console.log(user.photo);
      
    })

    return {
      myLogo,
      uploadImage,
      logo,
    };
  },
};
