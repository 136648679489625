
import { defineComponent, PropType } from 'vue';
import { Order } from '@/models';
import NumberFormatter from '@/components/NumberFormatter.vue';

export default defineComponent({
    props: {
        order: { type: Object as PropType<Order>, required: true }
    },
    setup({ order }) {
        return {
            order
        };
    },
    components: { NumberFormatter }
})
