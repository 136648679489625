import { ref, Ref, onMounted } from 'vue';
interface ToggleInterface {
    open: () => void;
    close: () => void;
    // layer:Ref<boolean>
}

export const useToggle = (className:String, layerName:string):ToggleInterface=>{

    const element:Ref<Element | null> = ref(document.querySelector('#'+className));
    const layer:Ref<Element | null> = ref(document.querySelector('#'+layerName));
    const open = ()=>{
        element.value!.classList.add('toggleSidebar');
        layer.value!.classList.remove('hidden');
        layer.value!.classList.add('block');


    }
    const close = ()=>{
       element.value!.classList.remove('toggleSidebar');
       layer.value!.classList.remove('block');
       layer.value!.classList.add('hidden');

    }
    onMounted( () => {
        element.value = document.querySelector('#'+className);
        layer.value = document.querySelector('#'+layerName);
        layer.value!.classList.add('hidden');
        //  console.log(layer);
        
    });



    return {
        open,
        close
    }

}
