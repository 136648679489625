enum Actions {
  // action types
  LOGIN = "login",
  PHONE_LOGIN = "phoneLogin",
  LOGOUT = "logout",
}

enum Mutations {
  // mutation types
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  PURGE_USER = "purge_user"
}

export { Actions, Mutations };
