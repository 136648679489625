export const defaultOrder = {
    delivery_to: "",
    to_coordinate: "",
    delivery_from: "",
    from_coordinate: "",
    delivery_fee: null,
    duration: null,
    delivery_distance: "",
    total: null,
    sub_total: null,
    delivery_method: "",
    foods: {},
    note: "",
    status: null,
    flow: {},
    code: null,
}

export const defaultResponseMeta = {
    per_page: null,
    total: null,
    last_page: null,
    current_page: null,
    next: null,
    prev: null,
    first: null,
    last: null,
    from: null,
    path: null,
    to: null,
}

export const defaultWithdrawal = {
    balance_before: null,
    balance_after: null,
    created_at: null,
    status: "",
}

export const defaultLocation = {
    id: null,
    areas: {
        title: ''
    },
    states: {
        title: ''
    },
    title: ''
}