import { ref, Ref } from 'vue'
import NetworkService from '../services/network-service';
import type { State, ResponseMeta } from '@/models';
import { cloneDeep } from "lodash";
import { useNotification } from "@kyvg/vue3-notification";

interface LocationComposable {
    isLoading: Ref<boolean>,
    locations:Ref<State[]>,
    getLocations: () => void;
}

export const useLocation = (): LocationComposable => {
    const isLoading = ref(false);
    const locations: Ref<State[]> = ref([]);
    const notification = useNotification();

    const getLocations = async (page:number=1, search?:any) => {
        isLoading.value = true
        let response = await NetworkService.get('rider/operations/states');
        
        locations.value = response.data.data
        locations.value?.map((e) => {
            e.name = e.title;
            e.towns?.map((e) => {
                e.name = e.title;
                e.areas?.map((e) => e.name = e.title)
            } )
        })
    }

    return {
        isLoading,
        locations,
        getLocations,                                                                           
    }
}
        