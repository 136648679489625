import { ref, Ref } from 'vue'
import NetworkService from '../services/network-service';
import type { User } from "@/models";
import { useStore } from 'vuex';
import { Mutations } from '@/store/enums/StoreEnums';

interface AuthComposable {
    username: Ref<string>,
    password: Ref<string>,  
    getUser: ()=> void,
    user: Ref<User>,
}

export const useAuth = ():AuthComposable =>{
    const username = ref('aladesiunpelumii@gmail.com');
    const password = ref('secret');
    const store = useStore(); 

    const user = ref(store.getters.currentUser); 

    const getUser = async ()=>{
        const response = await NetworkService.get("/rider/user");
        if(response.status != 200) return;
        store.commit(Mutations.SET_USER, {'data': response.data});

        user.value = response.data;
    }

    return {
        username,
        password,
        getUser, 
        user
    }
}