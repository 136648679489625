<template>
  <div>
    <router-view />
    <notifications :duration="3000" position="top right mt-[400px]"/>
    
  </div>
</template>
<script>
import { computed, defineComponent, nextTick, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
export default defineComponent({
  name: 'App',
  components: {
     
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    onMounted(() => {
      if (route.meta.AuthRequired || route.matched.find((d) => d.meta.AuthRequired)) {
        var user = localStorage.getItem('rideeat');
        if (!user) {
          if (route.meta.AllowNoAuth) {
            return false;
          }
          router.push({name: "login"})
        }
        user = decodeURIComponent(user);
        user = JSON.parse(user);

        store.commit('setUser', user);
      }
    })
  }
})

</script>


<style>

</style>
